import { HttpErrorResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { multiFactor } from '@angular/fire/auth';
import {
  ActivatedRouteSnapshot,
  CanActivateFn,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { Capacitor } from '@capacitor/core';
import { jwtDecode } from 'jwt-decode';
import { EMPTY, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  IAccountError,
  IDecodedEASIToken,
  PersonsService,
} from '@lancelot-frontend/api';
import { AppService } from '../app.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { SignedInUserService } from './user.service';

export const hasPerson: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'hasPerson',
    url,
    signedInUserService.signedInPerson$.pipe(
      map((person) => {
        if (!person) {
          return router.createUrlTree(['auth', 'login']);
        }

        return true;
      }),
    ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const isNotEmailVerified: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'isNotEmailVerified',
    url,
    signedInUserService.signedInPerson$.pipe(
      map((person) => {
        if (person?.emailVerified) {
          return router.createUrlTree(['user', 'dashboard']);
        }

        return true;
      }),
    ),
  );
};

export const doesNotNeedMultifactorAuth: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  if (Capacitor.isNativePlatform()) {
    return true;
  }

  const router = inject(Router);
  const app = inject(AppService);
  const auth = inject(AuthenticationService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'doesNotNeedMultifactorAuth',
    url,
    combineLatest(
      auth.firebaseUser$,
      signedInUserService.signedInUserEASIToken$,
    ).pipe(
      map(([firebaseUser, EASIToken]) => {
        if (
          EASIToken &&
          firebaseUser &&
          !multiFactor(firebaseUser).enrolledFactors.length
        ) {
          try {
            const { is_admin_user, sign_in_second_factor } =
              jwtDecode<IDecodedEASIToken>(EASIToken);

            if (is_admin_user && !sign_in_second_factor) {
              return router.parseUrl(
                '/auth/enroll-second-factor?easiAdmin=true',
              );
            }
            return true;
          } catch {
            return true;
          }
        }

        return true;
      }),
    ),
  );
};

export const isValid: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const personsService = inject(PersonsService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'isValid',
    url,
    signedInUserService.signedInPerson$.pipe(
      switchMap((person) => {
        if (
          !person ||
          person.licence ||
          !(
            person.firstName &&
            person.lastName &&
            person.birthdate &&
            person.email
          )
        ) {
          return of(person);
        }
        return personsService
          .checkAccountInformation({
            firstName: person.firstName,
            lastName: person.lastName,
            birthdate: person.birthdate,
            email: person.email,
          })
          .pipe(
            map((accountError) => {
              if (accountError) {
                // eslint-disable-next-line rxjs/throw-error
                throw accountError;
              } else {
                return person;
              }
            }),
            catchError((error: HttpErrorResponse | IAccountError) => {
              router.navigate(['auth', 'account-error'], {
                state:
                  error instanceof HttpErrorResponse ? undefined : { error },
              });
              return EMPTY;
            }),
          );
      }),
      map((person) => {
        if (!person?.valid) {
          return router.createUrlTree(['user', 'profile-validation']);
        }

        return true;
      }),
    ),
    { comeBackToRefusedUrlOnValueChange: true },
  );
};

export const isNotValid: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'isNotValid',
    url,
    signedInUserService.signedInPerson$.pipe(
      map((person) => {
        if (person?.valid) {
          return router.createUrlTree(['user', 'dashboard']);
        }

        return true;
      }),
    ),
  );
};

export const isTeacher: CanActivateFn = (
  route: ActivatedRouteSnapshot,
  { url }: RouterStateSnapshot,
) => {
  const router = inject(Router);
  const app = inject(AppService);
  const signedInUserService = inject(SignedInUserService);

  return app.addRouterGuard(
    'isTeacher',
    url,
    signedInUserService.signedInPerson$.pipe(
      map((person) => {
        if (!person?.teacher) {
          return router.createUrlTree(['user', 'dashboard']);
        }

        return true;
      }),
    ),
  );
};
